import hotel1 from "../../images/Nungambakam.jpg";
import hotel2 from "../../images/Kodambakkam.jpg";
import hotel3 from "../../images/Shalibanda.jpg";
import hotel4 from "../../images/Abids.jpg";


export const dataDigital = [
  {
    id: 1,
    title: "Nungambakam",
    price: "Location",
    category: "Description",
    linkImg: hotel1,
  },
  {
    id: 2,
    title: "Kodambakkam",
    price: "Location",
    category: "Description",
    linkImg: hotel2,
  },
  {
    id: 3,
    title: "Shalibanda",
    price: "Location",
    category: "Description",
    linkImg: hotel3,
  },
  {
    id: 4,
    title: "Abids",
    price: "Location",
    category: "Description",
    linkImg: hotel4,
  },

];
