import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Navbar from "../Navbar/Navbar";
import Navs from '../Navs/Navs';
import Intro from "../intro/intro";
import About from "../About/About";
import Banner from "../Banner/Banner.jsx";
// import Menu from "../Menu/Menu";
import Store from "../Store/Store";
import Restaurant from "../Restaurant/Restaurant";
import Faq from "../Faq/Faq";
// import Mobileapp from "../Mobileapp/Mobileapp";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";




function Homepage() {
  return (
    <div>
      {/* <Navbar /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description"
          content="Indulge in a quick delicious bite at Hapningz. Serving smiles and treating your taste buds ensures a satisfying experience every visit." />
        <meta name="title" content="Hapningz - One of the Fastest Growing QSR Franchise" />
        <title>Hapningz - One of the Fastest Growing QSR Franchise</title>
        <link rel="canonical" href="https://hapningz.com" />
      </Helmet>
      <Navs />
      <Intro />
      <About />
      <Banner />
      {/* <Menu /> */}
      <Store />
      <Restaurant />
      {/* <Faq /> */}
      {/* <Mobileapp /> */}
      <Footer />
    </div>
  )
}

export default Homepage;