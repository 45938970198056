import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Homepage from "./components/Home/Homepage";
import Aboutus from "./components/About us/Aboutus";
import MyStores from "./components/My Stores/MyStores";
import MyMenu from "./components/My Menu/MyMenu";
import MyFranchise from "./components/My Franchise/MyFranchise";
import Foodies from "./components/Foodies/Foodies";
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Homepage />}></Route>
        <Route exact path="/Aboutus" element={<Aboutus />}></Route>
        <Route exact path="/MyStores" element={<MyStores />}></Route>
        <Route exact path="/MyMenu" element={<MyMenu />}></Route>
        <Route exact path="/MyFranchise" element={<MyFranchise />}></Route>
        <Route exact path="/Foodies" element={<Foodies />}></Route>
        <Route exact path="/Contact" element={<Contact />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
