import React from "react";
import "./About.css";
import chef from "../../images/chef.png";
import chefarrow from "../../images/chefarrow.png";
import onlineorder from "../../images/onlineorder.png";
import service from "../../images/247.png";
import booking from "../../images/booking.png";
import redblur from "../../images/redblur.png";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about-us" id="about">
      <div className="container">
        <div className="row chef-about">
          <div className="col-lg-5 chef-img ">
            <img src={chef} className="chef-img-pose" alt="chefs" />
            <img src={chefarrow} className="chef-img-arrow" alt="chefsarrow" />
          </div>
          <div className="col-lg-7 chef-desc">
            <Carousel controls={false}>
              <Carousel.Item interval={4000} indicators="false">
                <h1 className="chef-desc-header">
                  We are<span style={{ color: "#2CBBBF" }}> More</span> than{" "}
                  <span style={{ color: "#e32227" }}>Multiple</span> Service
                </h1>
                <p className="chef-desc-para">
                  {" "}
                  This is a type of restaurant that typically serves food and
                  drink,in addition to light refreshments such as baked goods or
                  snacks.The term comes from the french word meaning food
                  festival.
                </p>
              </Carousel.Item>
              <Carousel.Item interval={4000}>
                <h1 className="chef-desc-header">
                  The Most<span style={{ color: "#2CBBBF" }}> Happening</span>{" "}
                  Chain of <span style={{ color: "#e32227" }}>QSR Brand</span>{" "}
                  in India!
                </h1>
                <p className="chef-desc-para">
                  Every meet brings an unbeatable episode of happiness and
                  unexpected happenings. And at Hapningz, people meet, dine,
                  share relishing moments, and rejoice in the most happening
                  experience they have ever indulged in.{" "}
                </p>
              </Carousel.Item>
            </Carousel>

            <img src={redblur} className="chef-blur" alt="blur vector" />

            <div className="chef-desc-list">
              <div className="chef-desc-list-1">
                <p className="chef-desc-icons">
                  <img src={onlineorder} alt="" /> Online Order
                </p>
                <p className="chef-desc-icons">
                  <img src={booking} alt="" /> Pre-Reservation
                </p>
                <p className="chef-desc-icons">
                  <img src={booking} alt="" /> Super Chef
                </p>
                <Link to="/Aboutus">
                  <button className="chef-login" onClick="/">
                    About Us
                  </button>
                </Link>
              </div>
              <div className="chef-desc-list-2">
                <p className="chef-desc-icons">
                  <img src={service} alt="" /> 24/7 Service
                </p>
                <p className="chef-desc-icons">
                  <img src={booking} alt="" /> Organized FoodHut Place
                </p>
                <p className="chef-desc-icons">
                  <img src={booking} alt="" /> Franchise
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
